import React from "react";
import { Layout } from "~/components";

import { GovernmentWorks as GovernmentWorksView } from "~/views";

const GovernmentWorks: React.FunctionComponent = () => (
  <Layout title="Government Works">
    <GovernmentWorksView />
  </Layout>
);

export default GovernmentWorks;
